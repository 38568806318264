<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        نقل اللاعبين
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3 text-center">
        <div class="mb-3">
          <label for="academieOneId" class="form-label">
            من اكاديمية
          </label>
          <select
            class="form-select form-control"
            id="academieOneId"
            name="academieOneId"
            v-model="academieOneId"
          >
            <option
              v-for="item of academieList"
              :key="item.id"
              :value="item.id"
              >{{ item.userName }}</option
            >
          </select>
        </div>

        <div class="mb-3">
          <label for="academieTwoId" class="form-label">
            الى اكاديمية
          </label>
          <select
            class="form-select form-control"
            id="academieTwoId"
            name="academieTwoId"
            v-model="body.academieTwoId"
          >
            <option
              v-for="item of academieList"
              :key="item.id"
              :value="item.id"
              >{{ item.userName }}</option
            >
          </select>
        </div>
        <div class="mb-3">
          <label for="selectedList" class="form-label">
            اللاعبين المراد نقلهم
          </label>
          <select
            class="form-select form-control"
            id="selectedList"
            name="selectedList"
            multiple="true"
            v-model.number="body.selectedList"
          >
            <option v-for="item of list" :key="item.id" :value="item.id">{{
              item.name
            }}</option>
          </select>
        </div>
        <Button
          label="نقل"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          @click="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      academieOneId: null,
      academieList: [],
      list: [],
      body: {
        academieTwoId: null,
        selectedList: [],
      },
    };
  },
  methods: {
    save() {
      if (
        this.body.academieTwoId &&
        this.body.selectedList.length > 0
      ) {
        this.$http
          .post(`players/updateMany`, this.body)
          .then(
            () => {
              this.academieOneId = null;
              this.body.academieTwoId = null;
              this.list = [];
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم النقل بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.get(`academies`).then(
      (response) => {
        this.academieList = response.data;
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );
  },

  watch: {
    academieOneId(val) {
      if (val) {
        this.$http.get(`players?academieId=${val}`).then(
          (response) => {
            this.list = response.data;
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      }
    },
  },
};
</script>
